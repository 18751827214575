export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'eqs',
	CLIENT_FEATURES: [],
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.352.1',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'dl-50',
		'inspections',
		'observations',
		'pipeline-integrity',
		'epi',
		'repairs'
	],
	TIMESTAMP: '2025-01-20T01:22:07.339Z',
	COMMIT: '92c56a86419def7494fc6f038dcb3e2cdc7b7271',
	BRANCH: 'master',
	API_SERVER: 'https://eqs-inspections-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
